<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 bookings-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_bookings',
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("bookings.bookings.declaration") }}
        </div>
        <template v-slot:right>
          <section v-if="valid">
            <button v-if="edit" @click="saveDeclaration()">
              <icon icon="#cx-hea1-save" />
            </button>
            <button v-else @click="edit = true">
              <icon icon="#cx-hea1-edit" />
            </button>
          </section>
        </template>
      </top-header-menu-wrapper>
    </template>
    <section v-if="booking">
      <section v-if="!edit">
        <ul class="clebex-item-section">
          <!-- Id -->
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  ID
                </label>
                <p class="text-label">
                  {{ booking.id }}
                </p>
              </div>
            </div>
          </li>
          <!-- Subject -->
          <li
            v-if="booking.subject"
            class="clebex-item-section-item full-right-underline"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.subject") }}
                </label>
                <p class="text-label">
                  {{ booking.subject }}
                </p>
              </div>
            </div>
          </li>
          <!-- Is-private flag -->
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.is-private") }}
                </label>
                <p class="text-label">
                  {{
                    displayLabelName(
                      `global.buttons.${booking.is_private ? "yes" : "no"}`
                    )
                  }}
                </p>
              </div>
            </div>
          </li>
          <!-- Date from - date to -->
          <li class="clebex-item-section-item">
            <div class="clebex-item-content-wrapper">
              <ul class="clebex-item-content-range">
                <li class="clebex-item-range from">
                  <div class="clebex-item-range-cta">
                    {{ displayDate(booking.datetime_from) }}
                  </div>
                </li>
                <li class="clebex-item-range separator">
                  <icon icon="#cx-app1-calendar" width="16" height="16" />
                </li>
                <li class="clebex-item-range to">
                  <div class="clebex-item-range-cta">
                    {{ displayDate(booking.datetime_to) }}
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <!-- Hour from - hour to -->
          <li class="clebex-item-section-item time">
            <div class="clebex-item-content-wrapper">
              <ul class="clebex-item-content-range">
                <li class="clebex-item-range from">
                  <div class="clebex-item-range-cta">
                    {{ displayTime(booking.datetime_from) }}
                  </div>
                </li>
                <li class="clebex-item-range separator">
                  <icon icon="#cx-app1-time" width="16" height="16" />
                </li>
                <li class="clebex-item-range to">
                  <div class="clebex-item-range-cta">
                    {{ displayTime(booking.datetime_to) }}
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <!-- Level -->
          <li
            v-if="booking.level"
            class="clebex-item-section-item full-right-underline"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.level") }}
                </label>
                <p class="text-label">
                  {{ booking.level }}
                </p>
              </div>
            </div>
          </li>
          <!-- Resource -->
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.resource") }}
                </label>
                <p class="text-label" v-if="booking.resource">
                  {{ booking.resource.name }}
                </p>
              </div>
            </div>
          </li>
          <!-- Host -->
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName("bookings.bookings.host")
                }}</label>
                <p class="text-label" v-if="booking.user">
                  {{ booking.user.first_name }}
                  {{ booking.user.last_name }}
                </p>
              </div>
            </div>
          </li>
          <!-- Status -->
          <li
            v-if="booking.status"
            class="clebex-item-section-item full-right-underline"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.status") }}
                </label>
                <p class="text-label" v-if="booking.status">
                  {{ booking.status.name }}
                </p>
              </div>
            </div>
          </li>
          <!-- Status -->
          <li
            v-if="booking.approval_status"
            class="clebex-item-section-item full-right-underline"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.approval-status") }}
                </label>
                <p class="text-label">
                  {{ booking.approval_status.name }}
                </p>
              </div>
            </div>
          </li>
          <li
            v-if="
              booking.approval_history &&
                booking.approval_history.length &&
                declarationApproveStatuses &&
                declarationApproveStatuses.length
            "
            class="clebex-item-section-item full-right-underline"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="notes">
                  {{ displayLabelName("bookings.bookings.approval-history") }}
                </label>
                <div
                  class="approval-history"
                  v-for="approvalStatus in booking.approval_history"
                  :key="approvalStatus.id"
                >
                  <div class="approval-history-date">
                    {{ displayDate(approvalStatus.created_at) }}
                    {{ displayTime(approvalStatus.created_at) }}
                  </div>
                  <div class="approval-history-status">
                    {{
                      declarationApproveStatuses.find(
                        (el) =>
                          el.id === approvalStatus.declaration_approve_status_id
                      ).name
                    }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="approvedStatusId" class="clebex-item-section">
          <clebex-button
            :label="displayLabelName('bookings.bookings.approve')"
            @buttonPressed="
              declarationStatusAssign({
                declarationId: declarationId,
                declarationApproveStatusId: approvedStatusId,
              }).then(() => {
                this.getDeclarationInfo();
              })
            "
          >
          </clebex-button>
        </div>
        <div v-if="declinedStatusId" class="clebex-item-section">
          <clebex-button
            :label="displayLabelName('bookings.bookings.decline')"
            @buttonPressed="
              declarationStatusAssign({
                declarationId: declarationId,
                declarationApproveStatusId: declinedStatusId,
              }).then(() => {
                this.getDeclarationInfo();
              })
            "
          >
          </clebex-button>
        </div>
        <div v-if="showCheckInButton" class="clebex-item-section">
          <clebex-button
            :label="displayLabelName('bookings.bookings.check-in')"
            @buttonPressed="getDeclarationInfo(true)"
          >
          </clebex-button>
        </div>
        <div
          v-if="
            booking.approval_status &&
              booking.approval_status.status == 'APPROVED' &&
              booking.has_services &&
              booking.service_order_id == null
          "
          class="clebex-item-section"
        >
          <clebex-button
            :label="displayLabelName('bookings.bookings.add-service-order')"
            @buttonPressed="addServiceOrder(booking)"
          >
          </clebex-button>
        </div>
        <div
          v-if="
            booking.approval_status &&
              booking.approval_status.status == 'APPROVED' &&
              booking.has_services &&
              booking.service_order_id != null
          "
          class="clebex-item-section"
        >
          <clebex-button
            :label="displayLabelName('bookings.bookings.edit-service-order')"
            @buttonPressed="editServiceOrder(booking)"
          >
          </clebex-button>
        </div>
      </section>
      <!-- EDIT mode -->
      <section v-else>
        <!-- Is-private flag -->
        <ul class="clebex-item-section">
          <li class="clebex-item-section-item">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label larger-label">
                  {{ displayLabelName("bookings.bookings.is-private") }}
                </label>
              </div>
              <div class="checkbox slide">
                <input
                  id="is-private"
                  type="checkbox"
                  v-model="booking.is_private"
                />
                <label for="is-private"></label>
              </div>
            </div>
          </li>
        </ul>
        <!-- Subject -->
        <ul class="clebex-item-section">
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("bookings.bookings.subject")
              }}</label>
              <input type="text" v-model="booking.subject" />
            </div>
          </li>
        </ul>
        <!-- Users -->
        <section v-if="can('BOOKINGS')">
          <users-section></users-section>
        </section>
        <!-- Date  -->
        <ul @click="selectSlots()" class="clebex-item-section cursor-pointer">
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer">{{
                displayLabelName("global.calendar.date")
              }}</label>
              <p class="text-label ellipsis">
                {{
                  displayDate(
                    selectedSlot
                      ? selectedSlot.slots[0].datetime_from
                      : booking.datetime_from
                  )
                }}
              </p>
            </div>
          </li>
          <li class="clebex-item-section-item time">
            <div class="clebex-item-content-wrapper">
              <ul class="clebex-item-content-range">
                <li class="clebex-item-range from">
                  <div class="clebex-item-range-cta">
                    {{
                      displayTime(
                        selectedSlot
                          ? selectedSlot.slots[0].datetime_from
                          : booking.datetime_from
                      )
                    }}
                  </div>
                </li>
                <li class="clebex-item-range separator">
                  <icon icon="#cx-app1-time" width="16" height="16" />
                </li>
                <li class="clebex-item-range to">
                  <div class="clebex-item-range-cta">
                    {{
                      displayTime(
                        selectedSlot
                          ? selectedSlot.slots.slice(-1)[0].datetime_to
                          : booking.datetime_to
                      )
                    }}
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <!-- Resource selection -->
        <ul
          v-if="declarableResources && declarableResources.length"
          class="clebex-item-section overflow-hidden"
        >
          <li
            class="clebex-item-section-item"
            :class="collapse['resources'] ? 'collapsed' : 'expanded'"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label larger-label">
                  {{ displayLabelName("bookings.bookings.resources") }}
                </label>
              </div>
              <span
                class="follow-up-icons cursor-pointer"
                @click="
                  collapse['resources']
                    ? (collapse['resources'] = false)
                    : (collapse['resources'] = true)
                "
              >
                <span class="follow-up-icon-item collapsable"
                  ><icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                /></span>
              </span>
            </div>
          </li>
          <section
            :class="collapse['resources'] ? 'collapsed' : 'expanded ten'"
          >
            <li
              v-for="resource in declarableResources.filter(
                (el) => selectedResourceId === el.id
              )"
              :key="resource.id"
              class="clebex-item-section-item no-bottom-border light no-separator"
            >
              <div class="clebex-item-content-wrapper cursor-pointer">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label cursor-pointer"
                    >{{
                      resource.translations.find((el) => el.locale === locale)
                        ? resource.translations.find(
                            (el) => el.locale === locale
                          ).name
                        : resource.name
                    }}
                  </label>
                </div>
                <span class="follow-up-icons">
                  <span class="follow-up-icon-item">
                    <div class="svg-icon">
                      <svg
                        width="12"
                        height="12"
                        style="width: 12px; height: 12px;"
                      >
                        <use xlink:href="#cx-app1-checkmark"></use>
                      </svg>
                    </div>
                  </span>
                </span>
              </div>
            </li>
            <li
              v-for="resource in declarableResources.filter(
                (el) => selectedResourceId !== el.id
              )"
              :key="resource.id"
              class="clebex-item-section-item no-bottom-border light no-separator"
            >
              <div
                @click="selectedResourceId = resource.id"
                class="clebex-item-content-wrapper cursor-pointer"
              >
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label cursor-pointer"
                    >{{
                      resource.translations.find((el) => el.locale === locale)
                        ? resource.translations.find(
                            (el) => el.locale === locale
                          ).name
                        : resource.name
                    }}
                  </label>
                </div>
              </div>
            </li>
          </section>
        </ul>
        <ul
          v-if="!declarableResources || !declarableResources.length"
          class="clebex-item-section overflow-hidden"
        >
          <li class="clebex-item-section-item">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label larger-label">
                  {{ displayLabelName("bookings.bookings.resources") }}
                </label>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item"
                  ><icon icon="#cx-men1-warning" width="16" height="16"
                /></span>
              </span>
            </div>
          </li>
          <li
            class="clebex-item-section-item no-bottom-border light no-separator"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label"
                  >{{ displayLabelName("bookings.bookings.no-resources") }}
                </label>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              v-if="
                booking &&
                  booking.status &&
                  booking.status.status !== 'ENDED' &&
                  !edit &&
                  editableUser
              "
              class="action-btn"
              @click="
                deleteDeclarations([booking.id]).then(() =>
                  $router.push({
                    name: 'r_bookings',
                  })
                )
              "
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <button v-if="edit" class="action-btn" @click="edit = false">
              {{ displayLabelName("global.buttons.cancel") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapGetters, mapState, mapActions } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import { getLang } from "@/services/http-service";
import ClebexButton from "@/components/global/Button";

export default {
  name: "BookingsDeclaration",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    ClebexButton,
    UsersSection: defineAsyncComponent(() =>
      import("@/components/bookings/UsersSection")
    ),
  },
  data() {
    return {
      showModal: false,
      selectedResourceId: null,
      edit: false,
      collapse: {},
      editableUser: false,
    };
  },
  created() {
    this.getDeclarationInfo();
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("declaration", ["declarationApproveStatuses"]),
    ...mapState("bookings", [
      "presets",
      "bookingsPresets",
      "booking",
      "declarableResources",
      "hostId",
      "availableHosts",
    ]),
    ...mapState("plan", ["selectedSlot"]),
    locale() {
      return getLang();
    },
    declarationId() {
      return this.$route.params.id;
    },
    approvedStatusId() {
      if (
        this.can("APPROVE") &&
        this.declarationApproveStatuses &&
        this.declarationApproveStatuses.length &&
        this.booking &&
        this.booking.approval_status &&
        this.booking.approval_status.status === "PENDING"
      ) {
        const approvedId = this.declarationApproveStatuses.find(
          (el) => el.status === "APPROVED"
        ).id;
        return approvedId;
      }
      return null;
    },
    declinedStatusId() {
      if (
        this.can("APPROVE") &&
        this.declarationApproveStatuses &&
        this.declarationApproveStatuses.length &&
        this.booking &&
        this.booking.approval_status &&
        ["PENDING", "APPROVED"].includes(this.booking.approval_status.status)
      ) {
        const declinedId = this.declarationApproveStatuses.find(
          (el) => el.status === "DECLINED"
        ).id;
        return declinedId;
      }
      return null;
    },
    valid() {
      if (this.booking) {
        if (
          DateTime.fromISO(this.booking.datetime_from) > DateTime.now() &&
          // this.booking.subject &&
          this.hostId &&
          this.selectedResourceId &&
          this.booking.status &&
          this.booking.status.status !== "ENDED" &&
          !this.booking.is_external &&
          this.editableUser
        ) {
          return true;
        }
      }
      return false;
    },
    showCheckInButton() {
      if (
        this.can("APPROVE") &&
        this.booking &&
        this.booking.status &&
        this.booking.status.status === "PRE_CHECK_IN" &&
        this.booking.approval_status &&
        this.booking.approval_status.status === "APPROVED"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("declaration", [
      "declarationStatusAssign",
      "declarationCheckIn",
    ]),
    ...mapActions("bookings", [
      "getBooking",
      "deleteDeclarations",
      "getResourcesAvailability",
      "updateDeclaration",
    ]),
    ...mapActions("plan", ["setSelectedSlot"]),
    addServiceOrder(booking) {
      this.$router.push({
        name: "r_services-add-booking-order",
        params: {
          order_resource_id: booking.resource_id,
          order_booking_id: booking.id,
          order_type_id: 0,
        },
      });
    },
    editServiceOrder(booking) {
      this.$router.push({
        name: "r_services-edit-order",
        params: { service_order_id: booking.service_order_id },
      });
    },
    getDeclarationInfo(checkIn) {
      this.getBooking({
        id: this.declarationId,
        params: {
          includes: [
            "resource",
            "status",
            "approval_status",
            "user",
            "approval_history",
          ],
        },
      }).then(() => {
        if (!this.booking) {
          this.$router.push({
            name: "r_bookings",
          });
        }
        this.edit = false;
        this.selectedResourceId = this.booking.resource_id;
        this.$store.commit("bookings/setHostId", this.booking.user_id, {
          root: true,
        });
        this.bookingsPresets.forEach((element) => {
          element.resources.forEach((el) => {
            let declaration = el.declarations.find(
              (e) => e.id === this.booking.id
            );
            if (declaration) {
              declaration.approval_status = this.booking.approval_status;
              declaration.status = this.booking.status;
            }
          });
        });
        this.checkIfEditableUser();
        if (
          checkIn &&
          this.booking.status &&
          this.booking.status.status === "PRE_CHECK_IN"
        ) {
          this.declarationCheckIn({
            id: this.declarationId,
            refresh: false,
          }).then(() => {
            this.getDeclarationInfo();
          });
        }
      });
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    selectSlots() {
      this.$router.push({ name: "r_bookings-declaration-edit-select-slots" });
    },
    saveDeclaration() {
      if (!this.valid) {
        this.getDeclarationInfo();
        return;
      }
      const postObject = {
        is_private: this.booking.is_private,
        subject: this.booking.subject,
        host_id: this.hostId,
        resource_id: this.selectedResourceId,
        type: this.selectedSlot ? this.selectedSlot.type : "QUARTER_HOUR",
        slots: this.selectedSlot
          ? this.selectedSlot.slots
          : [
              {
                datetime_from: this.booking.datetime_from,
                datetime_to: this.booking.datetime_to,
              },
            ],
      };
      this.updateDeclaration({
        postObject: postObject,
        declarationId: this.declarationId,
      }).then(() => {
        this.getDeclarationInfo();
      });
    },
    getInitialAvailibility() {
      const slots = {
        slots: [
          {
            datetime_from: this.booking.datetime_from,
            datetime_to: this.booking.datetime_to,
          },
        ],
        type: "QUARTER_HOUR",
      };
      this.getResourcesAvailability({
        slots: slots,
        preset: null,
        declarationId: this.booking.id,
        hostId: this.hostId,
      });
    },
    checkIfEditableUser() {
      if (this.availableHosts.find((el) => el.id === this.hostId)) {
        this.editableUser = true;
      } else {
        this.editableUser = false;
      }
    },
  },
  watch: {
    declarationId: {
      handler(val) {
        this.edit = false;
        this.editableUser = false;
        this.$store.commit("bookings/setDeclarableResources", null, {
          root: true,
        });
        if (val) {
          this.getDeclarationInfo();
        }
      },
    },
    selectedSlot(val) {
      if (val) {
        this.getResourcesAvailability({
          slots: val,
          preset: null,
          declarationId: this.declarationId,
          hostId: this.hostId,
        });
      } else {
        this.$store.commit("bookings/setDeclarableResources", null, {
          root: true,
        });
        this.getInitialAvailibility();
      }
    },
    edit(val) {
      this.setSelectedSlot(null);
      if (val) {
        this.getInitialAvailibility();
      }
    },
  },
  beforeUnmount() {
    this.$store.commit("bookings/setBooking", null, {
      root: true,
    });
    this.$store.commit("bookings/setDeclarableResources", null, {
      root: true,
    });
    this.setSelectedSlot(null);
  },
};
</script>
